import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { calculatePagination } from 'utils/helpers';
import { formatLinksListData, formatCurrentLink } from 'utils/dataParsers';

const loadingInitialState = {
    all: false,
    current: false
};

const searchParamsInitialState = {
    categories: [],
    category: {
        id: null,
        label: 'Select a category'
    },
    searchText: ''
};

const slice = createSlice({
    name: 'toolLinks',
    initialState: {
        current: null,
        lastFetch: null,
        list: [],
        loading: loadingInitialState,
        pagination: {
            firstIndex: 0,
            lastIndex: 0,
            totalResults: 0
        },
        searchParams: searchParamsInitialState
    },
    reducers: {
        toolLinksRequested: (toolLinks, action) => {
            toolLinks.loading.all = true;
        },

        toolLinksReceived: (toolLinks, action) => {
            toolLinks.list = formatLinksListData(action.payload?.documents);
            toolLinks.pagination = calculatePagination(action.payload?.pagination, action.payload?.totals);
            toolLinks.loading.all = false;
            toolLinks.lastFetch = Date.now();
        },

        toolLinksByCategoryReceived: (toolLinks, action) => {
            // TODO update this properly vvv
            const documents = action.payload?.documents?.filter((listing) => {
                return toolLinks.searchParams.categories.includes(listing.categoryId);
            });
            toolLinks.list = formatLinksListData(documents);
            toolLinks.pagination = calculatePagination(
                {
                    ...action.payload?.pagination,
                    nextPage:
                        documents?.length <= action.payload?.pagination?.pageSize ||
                        action.payload?.pagination?.currentPage ===
                            Math.ceil(documents?.length / action.payload?.pagination?.pageSize)
                            ? 0
                            : action.payload?.pagination?.currentPage + 1,
                    lastPage: Math.ceil(documents?.length / action.payload?.pagination?.pageSize)
                },
                documents?.length
            );
            toolLinks.loading.all = false;
            toolLinks.lastFetch = Date.now();
        },

        singleToolLinkRequested: (toolLinks, action) => {
            toolLinks.loading.current = true;
        },

        singleToolLinkReceived: (toolLinks, action) => {
            toolLinks.current = formatCurrentLink(action.payload?.documents[0]);
            toolLinks.loading.current = false;
        },

        toolLinksRequestFailed: (toolLinks, action) => {
            toolLinks.loading = loadingInitialState;
        },

        setToolLinksSearchText: (toolLinks, action) => {
            toolLinks.searchParams.searchText = action.payload;
        },

        setCategoriesFilter: (toolLinks, action) => {
            toolLinks.searchParams.categories = action.payload;
        },

        setToolLinksSearchCategory: (toolLinks, action) => {
            toolLinks.searchParams.category = action.payload;
        },

        clearSearchParams: (toolLinks, action) => {
            toolLinks.searchParams = searchParamsInitialState;
        }
    }
});

export const {
    clearSearchParams,
    setCategoriesFilter,
    setToolLinksSearchCategory,
    setToolLinksSearchText,
    singleToolLinkReceived,
    singleToolLinkRequested,
    toolLinksReceived,
    toolLinksByCategoryReceived,
    toolLinksRequestFailed,
    toolLinksRequested
} = slice.actions;

const url = '/List';

export const loadToolLinks =
    (queryParams = {}) =>
    (dispatch, getState) => {
        const { searchText } = getState().entities.toolLinks.searchParams;
        let { categories } = queryParams;
        let queryURI = [];

        if (searchText?.trim().length > 0 && categories?.length > 0 && Array.isArray(categories)) {
            categories = encodeURIComponent(categories.join(','));
            queryURI.push(`categoryIds=${categories}`);
        }

        if (searchText?.trim().length > 0) queryURI.push(`text=${searchText.trim()}`);

        queryURI = queryURI.length > 0 ? `/Search?${queryURI.join('&')}` : '';

        let onSuccess = toolLinksReceived.type;

        if (!searchText && categories?.length > 0 && Array.isArray(categories)) {
            // TODO update this from the backend vvv
            queryURI = queryURI + '?pageSize=9999';
            dispatch(setCategoriesFilter(categories));
            onSuccess = toolLinksByCategoryReceived.type;
        }

        return dispatch(
            apiCallBegan({
                apiId: 'main',
                url: url + queryURI,
                onStart: toolLinksRequested.type,
                onSuccess,
                onError: toolLinksRequestFailed.type
            })
        );
    };

export const changePage =
    (queryParams = {}) =>
    (dispatch, getState) => {
        const { searchText } = getState().entities.toolLinks.searchParams;
        let { categories, currentPage = 1 } = queryParams;
        let queryURI = [];

        if (searchText?.trim().length > 0 && categories?.length > 0 && Array.isArray(categories)) {
            categories = encodeURIComponent(categories.join(','));
            queryURI.push(`categoryIds=${categories}`);
        }

        if (searchText?.trim().length > 0) queryURI.push(`&text=${searchText.trim()}`);

        queryURI =
            queryURI.length > 0
                ? `/Search?pageSize=10&currentPage=${currentPage}${queryURI.join('&')}`
                : `?pageSize=10&currentPage=${currentPage}`;

        let onSuccess = toolLinksReceived.type;

        if (!searchText && categories?.length > 0 && Array.isArray(categories)) {
            dispatch(setCategoriesFilter(categories));
            onSuccess = toolLinksByCategoryReceived.type;
        }

        return dispatch(
            apiCallBegan({
                apiId: 'main',
                url: url + queryURI,
                onStart: toolLinksRequested.type,
                onSuccess,
                onError: toolLinksRequestFailed.type
            })
        );
    };

export const loadSingleToolLink = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}/${id}`,
            onStart: singleToolLinkRequested.type,
            onSuccess: singleToolLinkReceived.type,
            onError: toolLinksRequestFailed.type
        })
    );
};

export default slice.reducer;
