import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import ScrollReveal from 'utils/ScrollReveal';
import { ThemeProvider } from 'components/theme';

// Routes
import RoutesSwitch from 'utils/RoutesSwitch';

const store = configureStore();

const App = () => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        document.body.classList.add('is-loaded');
        childRef.current.init();
    }, [location]);

    return (
        <>
            <Provider store={store}>
                <ThemeProvider>
                    <ScrollReveal ref={childRef} children={() => <RoutesSwitch />} />
                </ThemeProvider>
            </Provider>
        </>
    );
};

export default App;
