import React, { useLayoutEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadTopCategories } from 'store/categories';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loader from 'components/common/Loader';
import BannerOne from 'components/banner/banner1/BannerOne';
import PopularCategories from 'components/other/categories/PopularCategories';
import { parsePath } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '10vh'
    },
    catArea: {
        position: 'relative',
        minHeight: '400px',
        padding: '5vh 10%'
    },
    catList: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '-15px',
        marginLeft: '-15px',
        marginTop: '25px'
    },
    seeAllBtn: {
        marginTop: '25px',
        '& a': {
            '& button': {
                minWidth: '165px'
            }
        }
    }
}));
function Home() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const hasMore = useSelector(({ entities }) => entities.categories.topHasMore);
    const pagination = useSelector(({ entities }) => entities.categories.topPagination);
    const topCategories = useSelector(({ entities }) => entities.categories.topList);
    const loadingCategories = useSelector(({ entities }) => entities.categories.loading.top);
    const loadingMoreCategories = useSelector(({ entities }) => entities.categories.loading.moreTop);

    const observer = useRef(null);
    const lastListingRef = useCallback(
        (node) => {
            if (loadingCategories) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) handleLoadMore();
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line
        [loadingCategories, hasMore]
    );

    useLayoutEffect(() => {
        dispatch(loadTopCategories());
        // dispatch(clearSearchParams());
    }, [dispatch]);

    const handleLoadMore = () => {
        dispatch(loadTopCategories({ currentPage: pagination?.nextPage }));
    };

    return (
        <div className={classes.root}>
            <BannerOne />

            <main>
                <Container maxWidth="lg">
                    <Paper
                        component={Grid}
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={classes.catArea}
                    >
                        <Loader loading={loadingCategories} />
                        {!loadingCategories && (
                            <>
                                <Grid item xs={12} className={classes.catList}>
                                    {topCategories?.length > 0 ? (
                                        <PopularCategories catitems={topCategories} />
                                    ) : (
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Typography variant="body1" color="textSecondary">
                                                No results
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Loader style={{ minHeight: '40vh' }} loading={loadingMoreCategories} />
                                {!loadingCategories && !loadingMoreCategories && hasMore && (
                                    <Grid item xs={12} container justifyContent="center" className={classes.seeAllBtn}>
                                        <div ref={lastListingRef}>
                                            <Button onClick={handleLoadMore} type="button">
                                                Load more
                                            </Button>
                                        </div>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={10}
                                    md={4}
                                    lg={2}
                                    container
                                    justifyContent="center"
                                    className={classes.seeAllBtn}
                                >
                                    <Link to={parsePath('/categories/all')}>
                                        <Button>See All</Button>
                                    </Link>
                                </Grid>
                            </>
                        )}
                    </Paper>
                </Container>
            </main>
        </div>
    );
}

export default Home;
