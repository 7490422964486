import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { categoriesFilterUpdated } from 'store/ui';
import WidgetsWrapper from './widgets/WidgetsWrapper';
import CheckboxWidget from './widgets/CheckboxWidget';

function ListingListSidebar() {
    const dispatch = useDispatch();
    const categoriesWidget = useSelector(({ ui }) => ui.listingResults.filters.categories);

    const handleFiltersChange = (ev) => {
        const { value, checked } = ev.target;
        dispatch(categoriesFilterUpdated({ id: value, active: checked }));
    };

    return (
        <>
            <WidgetsWrapper>
                <CheckboxWidget
                    items={categoriesWidget.items}
                    title={categoriesWidget.title}
                    onChange={handleFiltersChange}
                />
            </WidgetsWrapper>
        </>
    );
}

export default ListingListSidebar;
