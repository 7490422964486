import { parsePath } from './helpers';

export function formatLinksListData(linksArray) {
    return linksArray.map((link) => formatLink(link));
}

function formatLink(link) {
    return {
        abstract: link.subText,
        categoryId: link.categoryId,
        description: link.description,
        featured: link.featured,
        featuredPositioning: link.featuredPositioning,
        features: link.features,
        id: link._Id,
        images: link.images,
        isLightLogo: link.isLightLogo,
        listId: link.listId,
        logo: link.logo,
        positioning: link.positioning,
        title: link.title,
        url: link.link,
        visible: link.visible
    };
}

export function formatCurrentLink(link, categoriesArray) {
    if (!link) return null;
    return {
        abstract: link.subText,
        categoryId: link.categoryId,
        description: link.description,
        descriptiontitle: 'Description',
        featurestitle: 'Features',
        images: {
            items: link.images.map((img) => ({ img: img?.image })),
            slideDots: link.images.map((img) => ({ img: img?.image }))
        },
        isLightLogo: link.isLightLogo,
        logo: link.logo,
        sidebar: {
            featuresWidget: {
                lists: link.features,
                title: 'Features'
            },
            tagsWidget: {
                lists: link.tags,
                title: 'Tags Cloud'
            }
        },
        title: link.title,
        url: link.link
    };
}

export function formatCategoriesList(categoriesArray) {
    return categoriesArray
        .filter((cat) => cat.listingCount !== 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((category) => formatSingleCategory(category));
}

function formatSingleCategory(category) {
    return {
        icon: category.logo,
        links: category.links,
        listingCount: category.listingCount,
        id: category.categoryId,
        parentId: category.parentId,
        stitle: `${category.listingCount || 0} listings`,
        title: category.name,
        url: parsePath(`/categories/${category.categoryId}`)
    };
}

export function formatCurrentCategory(category, categoriesArray = [], linksArray = []) {
    return {
        description: category.description,
        icon: category.logo,
        id: category.id,
        parentId: category.parentId,
        title: category.name
    };
}
