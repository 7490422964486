import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { parsePath } from 'utils/helpers';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from 'components/common/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '120px 0 20px 0',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        '& .content': {
            textAlign: 'center',
            '& .title': {
                fontSize: '36px',
                textTransform: 'capitalize',
                fontWeight: 700,
                color: theme.palette.text.primary,
                marginBottom: '22px',
                [theme.breakpoints.up('sm')]: {
                    fontSize: '40px'
                }
            },
            '& .list li': {
                display: 'inline-block',
                textTransform: 'capitalize',
                fontSize: '15px',
                fontWeight: 500,
                position: 'relative',
                paddingRight: '22px',
                [theme.breakpoints.up('md')]: {
                    fontSize: '16px'
                },
                '&:last-child': {
                    paddingRight: 0
                },
                '& a': {
                    transition: 'all 0.3s',
                    color: theme.palette.text.primary,
                    '&:hover': {
                        color: theme.palette.primary.main
                    }
                },
                '&.list-item:after': {
                    position: 'absolute',
                    content: '-',
                    top: 0,
                    right: 0,
                    padding: '0 7px',
                    fontSize: '18px'
                }
            },
            '& > .icon img': {
                height: '4rem',
                marginBottom: '1rem',
                color: theme.palette.text.primary
            }
        }
    }
}));

function Breadcrumb({
    CurrentPgIcon,
    CurrentPgTitle,
    divider = '>',
    MenuPgTitle,
    MenuPgLink,
    img,
    children,
    ParentCategory
}) {
    const classes = useStyles();

    const [parentCategoryTitle, setParentCategoryTitle] = useState(null);
    const categoryLoading = useSelector(({ entities }) => entities.categories.loading);
    const categoriesList = useSelector(({ ui }) => ui.listingResults.filters.categories.items);

    useEffect(() => {
        if (ParentCategory === -1) return setParentCategoryTitle(null);
        else {
            const category = categoriesList?.filter(
                (category) => category.id?.toString() === ParentCategory?.toString()
            );
            if (category && category.length > 0) setParentCategoryTitle(category[0].text);
        }
    }, [categoriesList, ParentCategory]);

    return (
        <header className={classes.root}>
            <div>
                <Loader style={{ minHeight: '20vh' }} loading={categoryLoading.currentDetails} />
                {!categoryLoading.currentDetails && (
                    <Container maxWidth="md">
                        <Grid container justifyContent="center">
                            <Grid item lg={12}>
                                <div className="content">
                                    {CurrentPgIcon && (
                                        <div className="icon">
                                            <img src={CurrentPgIcon} alt="Category logo" />
                                        </div>
                                    )}
                                    <Typography variant="h2" className="title">
                                        {CurrentPgTitle}
                                    </Typography>
                                    <ul className="list">
                                        <li className="list-item">
                                            <Link to={parsePath()}>home</Link>
                                        </li>
                                        {MenuPgTitle && <li>{divider}</li>}
                                        {MenuPgTitle && (
                                            <li className="list-item">
                                                <Link to={parsePath(MenuPgLink)}>{MenuPgTitle}</Link>
                                            </li>
                                        )}
                                        {parentCategoryTitle && <li>{divider}</li>}
                                        {parentCategoryTitle && (
                                            <li className="list-item">
                                                <Link to={parsePath(`/categories/${ParentCategory}`)}>
                                                    {parentCategoryTitle}
                                                </Link>
                                            </li>
                                        )}
                                        <li>{divider}</li>
                                        <li>{CurrentPgTitle}</li>
                                    </ul>
                                </div>
                                {children}
                            </Grid>
                        </Grid>
                    </Container>
                )}
            </div>
        </header>
    );
}

export default Breadcrumb;
