import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { parsePath } from 'utils/helpers';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Title from 'components/headings/Title';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        paddingTop: '140px',
        '& img': {
            width: '100%'
        }
    }
}));

const TermsAndConditions = () => {
    const classes = useStyles();

    const sectiondata = useSelector(({ ui }) => ui.textContent);

    return (
        <main className={classes.root}>
            <section>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid container spacing={4} justifyContent="center" item xs={6}>
                            <Grid item xs={12}>
                                <Title
                                    title={sectiondata.termsAndConditions.title}
                                    subtitle={sectiondata.termsAndConditions.content}
                                />
                            </Grid>
                            <Grid container justifyContent="center" item xs={12}>
                                <Link to={parsePath()}>
                                    <Button>Back to Home</Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </main>
    );
};

export default TermsAndConditions;
