export function createCategoriesFilter(categories = []) {
    return {
        title: 'Filter by Category',
        items: categories.map((category) => {
            return {
                id: category.id,
                categoryId: category.id,
                text: category.title,
                number: category.listingCount,
                active: category.active || false,
                url: category.url,
                icon: category.icon
            };
        })
    };
}

export function createCategoriesSelect(categories = []) {
    const categoryList = categories.map((category, index) => ({
        value: index + 2,
        label: category.title
    }));
    return {
        selectedCatOp: null,
        categories: [
            {
                value: 0,
                label: 'Select a category'
            },
            {
                value: 1,
                label: 'All Category'
            },
            ...categoryList
        ]
    };
}
