import React from 'react';
import { Link } from 'react-router-dom';

export default function Button({ text, type = 'link', url, className, children, onClick = () => {}, ...otherProps }) {
    return (
        <>
            {type === 'link' && (
                <Link to={url} className={'theme-btn ' + className} {...otherProps}>
                    {children} {text}
                </Link>
            )}
            {type === 'button' && (
                <button onClick={onClick} className={'theme-btn ' + className} {...otherProps}>
                    {children} {text}
                </button>
            )}
        </>
    );
}
