import React from 'react';
// import { useSelector } from 'react-redux';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { parsePath } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        minHeight: '200px',
        maxWidth: '260px',
        flexGrow: 1,
        border: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
        borderRadius: '5px',
        transition: 'all .3s ease',
        '& figcaption': {
            width: '100%',
            height: '100%',
            '& a': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                color: theme.palette.text.primary
            }
        },
        '&:hover': {
            transform: 'scale(1.04)',
            boxShadow: theme.shadows[1],
            '& a': {
                '&>div:nth-of-type(2)': {
                    backgroundColor: theme.palette.primary.main
                },
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.primary.dark}!important`
                }
            }
        }
    },
    icon: {
        '& img': {
            color: theme.palette.primary.main,
            width: '2.5rem',
            transition: 'all .3s ease'
        }
    },
    title: {
        margin: '20px 0',
        fontWeight: '500',
        fontSize: '1.2rem'
    },
    btn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '999px',
        padding: '2px 20px',
        transition: 'all .3s ease'
    }
}));

const SimpleCard = ({ title, btnText, icon, href }) => {
    const classes = useStyles();

    // const appLogo = useSelector(({ ui }) => ui.appInformation?.appLogo);

    return (
        <Grid container justifyContent="center" item xs={12} sm={6} md={4} lg={3}>
            <figure className={classes.root}>
                <figcaption>
                    <Link to={href}>
                        {icon && (
                            <div className={classes.icon}>
                                <img src={icon} alt={title} />
                            </div>
                        )}

                        <Typography className={classes.title} variant="body1">
                            {title}
                        </Typography>
                        <div className={classes.btn}>{btnText}</div>
                    </Link>
                </figcaption>
            </figure>
        </Grid>
    );
};

export default SimpleCard;
