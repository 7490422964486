import React from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { parsePath } from 'utils/helpers';
import { setToolLinksSearchText } from 'store/links';
import SearchInput from 'components/forms/SearchInput';

function BannerOneSearchInput({ history }) {
    const dispatch = useDispatch();

    const handleTextChange = (e) => {
        dispatch(setToolLinksSearchText(e.target.value));
    };

    const handleSubmit = () => {
        history.push(parsePath('/search'));
    };

    return (
        <SearchInput
            style={{
                marginTop: '20px'
            }}
            placeholder="What are you looking for?"
            onChange={handleTextChange}
            onSubmit={handleSubmit}
        />
    );
}

export default withRouter(BannerOneSearchInput);
