import Home from 'pages/homes/Home';
import AllCategories from 'pages/categories/AllCategories';
import SearchResults from 'pages/listings/ListingResults';
import SingleCategory from 'pages/listings/SingleCategory';
// import ListingDetails from 'pages/listings/ListingDetails';
import Mantainance from 'pages/Mantainance';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
import Error from 'pages/Error';

import { mantainancePath, parsePath } from 'utils/helpers';

export const routes = [
    {
        path: parsePath('/'),
        component: Home,
        exact: true
        // headerShadow: false
    },
    {
        path: parsePath('/categories'),
        redirectTo: parsePath('/categories/all'),
        exact: true
    },
    {
        path: parsePath('/categories/all'),
        component: AllCategories,
        exact: true
    },
    {
        path: parsePath('/categories/:categoryid'),
        component: SingleCategory
    },
    {
        path: parsePath('/search'),
        component: SearchResults
    },
    // {
    //     path: parsePath('/link/:linkid'),
    //     component: ListingDetails
    // },
    {
        path: parsePath('/privacy-policy'),
        component: PrivacyPolicy
    },
    {
        path: parsePath('/terms&conditions'),
        component: TermsAndConditions
    },
    {
        path: parsePath(mantainancePath),
        component: Mantainance,
        layout: false,
        noLayoutFooter: true
    },
    {
        component: Error
    }
];
