import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClampLines from 'react-clamp-lines';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { parsePath } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    cardImage: {
        height: '120px',
        width: '150px',
        backgroundSize: 'contain',
        margin: '10px',
        backgroundColor: (link) => (link.isLightLogo ? 'rgba(0,0,0,0.15)' : ''),
        [theme.breakpoints.up('sm')]: {
            height: '140px',
            width: '180px'
        },
        [theme.breakpoints.up('md')]: {
            height: '220px',
            width: '290px'
        }
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        '& .card-content': {
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0 -15px',
            width: '100%',
            '& .card-content-text': {
                flex: '0 0 66.666667%',
                maxWidth: '66.666667%',
                padding: '10px',
                '& .card-meta': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '13px',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    position: 'relative',
                    color: '#808996',
                    '& .imageWrapper': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0,
                        flexGrow: 0,
                        marginRight: '10px',
                        fontSize: '20px',
                        color: '#fff',
                        width: '33px',
                        height: '33px',
                        backgroundColor: theme.palette.primary.main,
                        lineHeight: '29px',
                        textAlign: 'center',
                        borderRadius: '50%',
                        '& > img': {
                            width: '60%'
                        }
                    },
                    '& .MuiTypography-root': {
                        maxWidth: 'calc(100% - 53px)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                },
                '& .card-title': {
                    fontSize: '18px',
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    marginTop: '24px',
                    marginBottom: '5px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    '& i': {
                        color: '#40cc6f',
                        '& svg': {
                            marginTop: '-3px'
                        }
                    }
                },

                '& .card-sub': {
                    fontSize: '15px',
                    color: '#808996',
                    fontWeight: 400,
                    width: '85%'
                }
            },
            '& .card-content-btn': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '0 0 33.333333%',
                maxWidth: '33.333333%'
            }
        }
    }
}));

const LinkItemHorizontal = ({ link }) => {
    const classes = useStyles(link);

    const [category, setCategory] = useState(null);
    const appLogo = useSelector(({ ui }) => ui.appInformation?.appLogo);
    const categories = useSelector(({ ui }) => ui.listingResults.filters.categories.items);

    useLayoutEffect(() => {
        if (link?.categoryId) {
            const filteredCategories = categories.filter(
                (cat) => cat.categoryId.toString() === link.categoryId.toString()
            );
            if (filteredCategories[0]) {
                setCategory({
                    title: filteredCategories[0].text,
                    icon: filteredCategories[0].icon,
                    id: filteredCategories[0].categoryId
                });
            }
        }
    }, [link?.categoryId, categories]);

    return (
        <Card className={classes.root}>
            <a target="_blank" rel="noreferrer" href={link?.url}>
                <CardMedia className={classes.cardImage} image={link?.logo || parsePath(appLogo)} />
            </a>
            <CardContent className={classes.cardContent}>
                <div className="card-content">
                    <div className="card-content-text">
                        {category && (
                            <Link to={parsePath(`/categories/${category.id}`)} className="card-meta">
                                {category.icon && (
                                    <div className="imageWrapper">
                                        <img src={category.icon} alt="Icon" />
                                    </div>
                                )}{' '}
                                <Typography variant="body2" color="textPrimary">
                                    {category.title}
                                </Typography>
                            </Link>
                        )}
                        {/* <Link to={parsePath(`/link/${link?.listId}`)}> */}
                        <Typography variant="h4" className="card-title">
                            {link?.title}
                        </Typography>
                        <ClampLines
                            text={link?.abstract}
                            id={link?.id}
                            lines={3}
                            ellipsis="..."
                            buttons={false}
                            className="card-sub"
                            innerElement={Typography}
                            variant="body1"
                        />
                        {/* </Link> */}
                    </div>
                    <div className="card-content-btn">
                        <a target="_blank" rel="noreferrer" href={link?.url}>
                            <Button>Visit Website</Button>
                        </a>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default LinkItemHorizontal;
