import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { calculatePagination } from 'utils/helpers';
import { formatCategoriesList, formatLinksListData, formatCurrentCategory } from 'utils/dataParsers';

const loadingInitialState = {
    all: false,
    currentDetails: false,
    currentListings: false,
    currentMoreListings: false,
    currentSubcategories: false,
    more: false,
    moreTop: false,
    top: false
};

const currentCategoryInitialState = {
    childCategories: [],
    description: '',
    hasMore: false,
    icon: '',
    links: [],
    name: 'All categories',
    pagination: {
        firstIndex: 0,
        lastIndex: 0,
        totalResults: 0
    }
};

const slice = createSlice({
    name: 'categories',
    initialState: {
        current: currentCategoryInitialState,
        hasMore: false,
        lastFetch: null,
        lastFetchTop: null,
        list: [],
        loading: loadingInitialState,
        pagination: {
            currentPage: 0,
            firstPage: 0,
            lastPage: 0,
            nextPage: 0,
            previousPage: 0
        },
        searchText: '',
        topHasMore: false,
        topList: [],
        topPagination: {}
    },
    reducers: {
        categoriesRequested: (categories, action) => {
            categories.loading.all = true;
        },

        topCategoriesRequested: (categories, action) => {
            categories.loading.top = true;
        },

        moreTopCategoriesRequested: (categories, action) => {
            categories.loading.moreTop = true;
        },

        cleanCurrentCategory: (categories, action) => {
            categories.current = currentCategoryInitialState;
        },

        currentCategoryRequested: (categories, action) => {
            categories.loading.currentDetails = true;
        },

        currentCategoryListingsRequested: (categories, action) => {
            categories.loading.currentListings = true;
        },

        currentCategoryMoreListingsRequested: (categories, action) => {
            categories.loading.currentMoreListings = true;
        },

        currentCategoryChildrenRequested: (categories, action) => {
            categories.loading.currentSubcategories = true;
        },

        categoriesReceived: (categories, action) => {
            categories.list = formatCategoriesList(action.payload.documents);
            categories.pagination = action.payload.pagination;
            categories.hasMore = action.payload.pagination.nextPage !== 0;
            categories.loading.all = false;
            categories.lastFetch = Date.now();
        },

        moreCategoriesRequested: (categories, action) => {
            categories.loading.more = true;
        },

        moreCategoriesReceived: (categories, action) => {
            categories.list = [...categories.list, ...formatCategoriesList(action.payload?.documents)];
            categories.pagination = action.payload?.pagination;
            categories.hasMore = action.payload?.pagination?.nextPage !== 0;
            categories.loading.more = false;
            categories.lastFetch = Date.now();
        },

        topCategoriesReceived: (categories, action) => {
            categories.topList =
                action.payload?.pagination?.currentPage === 1
                    ? formatCategoriesList(action.payload?.documents)
                    : [...categories.topList, ...formatCategoriesList(action.payload?.documents)];
            categories.topPagination = action.payload.pagination;
            categories.topHasMore = action.payload?.pagination?.nextPage !== 0;
            categories.loading.top = false;
            categories.loading.moreTop = false;
            categories.lastFetchTop = Date.now();
        },

        singleCategoryReceived: (categories, action) => {
            categories.current = formatCurrentCategory(action.payload);
            categories.loading.currentDetails = false;
        },

        singleCategoryListingsReceived: (categories, action) => {
            categories.current.links =
                action.payload?.pagination?.currentPage === 1
                    ? formatLinksListData(action.payload?.documents)
                    : [...categories.current.links, ...formatLinksListData(action.payload?.documents)];
            categories.current.hasMore = action.payload?.pagination?.nextPage !== 0;
            categories.current.pagination = calculatePagination(action.payload?.pagination, action.payload?.totals);
            categories.loading.currentListings = false;
            categories.loading.currentMoreListings = false;
        },

        singleCategoryChildrenReceived: (categories, action) => {
            categories.current.childCategories = formatCategoriesList(action.payload.documents);
            categories.loading.currentSubcategories = false;
        },

        categoriesRequestFailed: (categories, action) => {
            categories.loading = loadingInitialState;
        },

        setCategoriesSearchText: (categories, action) => {
            categories.searchText = action.payload;
        }
    }
});

export const {
    categoriesReceived,
    categoriesRequested,
    categoriesRequestFailed,
    cleanCurrentCategory,
    currentCategoryRequested,
    currentCategoryChildrenRequested,
    currentCategoryListingsRequested,
    moreCategoriesRequested,
    moreCategoriesReceived,
    setCategoriesSearchText,
    singleCategoryReceived,
    singleCategoryChildrenReceived,
    singleCategoryListingsReceived,
    currentCategoryMoreListingsRequested,
    topCategoriesReceived,
    topCategoriesRequested,
    moreTopCategoriesRequested
} = slice.actions;

const url = '/Category';

export const loadCategories = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}?featured=-1&pageSize=12`,
            onStart: categoriesRequested.type,
            onSuccess: categoriesReceived.type,
            onError: categoriesRequestFailed.type
        })
    );
};

export const loadMoreCategories =
    ({ currentPage }) =>
    (dispatch, getState) => {
        return dispatch(
            apiCallBegan({
                apiId: 'main',
                url: `${url}?featured=-1&pageSize=12&currentPage=${currentPage}`,
                onStart: moreCategoriesRequested.type,
                onSuccess: moreCategoriesReceived.type,
                onError: categoriesRequestFailed.type
            })
        );
    };

export const loadTopCategories = (props) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}?featured=1&pageSize=12&currentPage=${props?.currentPage || 1}`,
            onStart: props?.currentPage === 1 ? topCategoriesRequested.type : moreTopCategoriesRequested.type,
            onSuccess: topCategoriesReceived.type,
            onError: categoriesRequestFailed.type
        })
    );
};

export const loadSingleCategory =
    ({ id, currentPage }) =>
    async (dispatch, getState) => {
        if (!currentPage || currentPage === '1') {
            await loadCategoryDetails(id, dispatch);
            await loadCategorySubcategories(id, dispatch);
        }
        return await loadCategoryListings({ id, dispatch, currentPage });
    };

const loadCategoryDetails = async (id, dispatch) => {
    return await dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}/${id}`,
            onStart: currentCategoryRequested.type,
            onSuccess: singleCategoryReceived.type,
            onError: categoriesRequestFailed.type
        })
    );
};

const loadCategorySubcategories = async (id, dispatch) => {
    return await dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}/${id}/subcategories`,
            onStart: currentCategoryChildrenRequested.type,
            onSuccess: singleCategoryChildrenReceived.type,
            onError: categoriesRequestFailed.type
        })
    );
};

const loadCategoryListings = async ({ id, dispatch, currentPage }) => {
    return await dispatch(
        apiCallBegan({
            apiId: 'main',
            url: `${url}/${id}/listings?pageSize=12&currentPage=${currentPage || 1}`,
            onStart:
                currentPage === 1 ? currentCategoryListingsRequested.type : currentCategoryMoreListingsRequested.type,
            onSuccess: singleCategoryListingsReceived.type,
            onError: categoriesRequestFailed.type
        })
    );
};

export default slice.reducer;
