import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { listingResultsGridMode, listingResultsListMode } from 'store/ui';
import { cleanCurrentCategory, loadSingleCategory } from 'store/categories';
import { loadCategoriesFilter } from 'store/ui';

import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BannerOneCategories from 'components/banner/banner1/BannerOneCategories';
import Breadcrumb from 'components/common/Breadcrumb';
import Button from 'components/common/Button';
import LinksList from 'components/toollinks/LinksList';
import Loader from 'components/common/Loader';
import ResultsHeader from 'components/common/ResultsHeader';
import TitleDecoration from 'components/headings/TitleDecoration';
import breadcrumbimg from 'assets/images/header-bg.jpg';

const useStyles = makeStyles((theme) => ({
    button: {
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: '10vh'
    },
    resultsBar: {
        margin: '20px 0'
    },
    resultsList: {
        margin: '20px 0'
    },
    sectionHeading: {
        padding: '0 5vw'
    }
}));

const state = {
    bdimg: breadcrumbimg
};
function SingleCategory() {
    const { categoryid } = useParams();
    const dispatch = useDispatch();

    const classes = useStyles();

    const [top3Categories, setTop3Categories] = useState(null);
    const [restCategories, setRestCategories] = useState(null);

    const currentCategory = useSelector(({ entities }) => entities.categories.current);
    const hasMore = useSelector(({ entities }) => entities.categories.current.hasMore);
    const loadingCategories = useSelector(({ entities }) => entities.categories.loading);
    const listingsPagination = useSelector(({ entities }) => entities.categories.current.pagination);
    const viewMode = useSelector(({ ui }) => ui.listingResults.viewMode);

    const observer = useRef(null);
    const lastListingRef = useCallback(
        (node) => {
            if (
                loadingCategories?.currentListings ||
                loadingCategories?.currentMoreListings ||
                loadingCategories?.currentDetails ||
                loadingCategories?.currentSubcategories ||
                currentCategory?.links?.length === 0
            )
                return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) handleLoadMore();
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line
        [loadingCategories?.currentListings, hasMore]
    );

    useLayoutEffect(() => {
        if (categoryid && categoryid !== 'undefined') {
            dispatch(loadCategoriesFilter());
            dispatch(loadSingleCategory({ id: categoryid }));
        }

        return () => dispatch(cleanCurrentCategory());
        //eslint-disable-next-line
    }, [categoryid, dispatch]);

    useEffect(() => {
        const links = currentCategory?.links;
        if (links?.length > 0) {
            if (links.length > 3) {
                const top3 = links.slice(0, 3);
                const rest = links.slice(3, links.length);
                setTop3Categories(top3);
                return setRestCategories(rest);
            }
            setTop3Categories(links);
        }
        return () => {
            setTop3Categories(null);
            setRestCategories(null);
        };
    }, [currentCategory?.links]);

    // const handleViewGridMode = () => dispatch(listingResultsGridMode());
    // const handleViewListMode = () => dispatch(listingResultsListMode());

    const handleLoadMore = () => {
        if (categoryid && categoryid !== 'undefined')
            dispatch(loadSingleCategory({ currentPage: listingsPagination.nextPage, id: categoryid }));
    };

    return (
        <main>
            {/* Breadcrumb */}
            <Breadcrumb
                CurrentPgIcon={currentCategory.icon}
                CurrentPgTitle={currentCategory.title}
                MenuPgTitle="Categories"
                MenuPgLink="/categories"
                ParentCategory={currentCategory.parentId}
                img={state.bdimg}
            >
                <Loader style={{ minHeight: '40vh' }} loading={loadingCategories.currentSubcategories} />
                {!loadingCategories.currentSubcategories && (
                    <BannerOneCategories
                        description={currentCategory.description}
                        items={currentCategory.childCategories}
                    />
                )}
            </Breadcrumb>

            {currentCategory.childCategories?.length < 1 && (
                <Container maxWidth="lg">
                    <Grid container className={classes.resultsBar} justifyContent="center">
                        <Loader style={{ minHeight: '40vh' }} loading={loadingCategories.currentListings} />
                        {!loadingCategories.currentListings && (
                            <>
                                {top3Categories ? (
                                    <Grid container item xs={12} md={10} justifyContent="center">
                                        <Grid xs={12} md={4} item className={classes.sectionHeading}>
                                            <Typography variant="h4" color="textPrimary">
                                                TOP 3
                                            </Typography>
                                            <TitleDecoration />
                                        </Grid>
                                        <Grid xs={12} md={8} item className={classes.sectionHeading}>
                                            <ResultsHeader
                                                pagination={{ ...listingsPagination, firstIndex: 1 }}
                                                // viewMode={viewMode}
                                                // onGridViewClick={handleViewGridMode}
                                                // onListViewClick={handleViewListMode}
                                            />
                                        </Grid>
                                        <Grid
                                            className={classes.resultsList}
                                            container
                                            item
                                            xs={12}
                                            spacing={2}
                                            justifyContent="center"
                                        >
                                            <LinksList viewMode={viewMode} links={top3Categories} />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid className={classes.resultsList} container justifyContent="center">
                                        <Typography variant="body1" color="textSecondary">
                                            No results
                                        </Typography>
                                    </Grid>
                                )}

                                {restCategories && (
                                    <>
                                        <Grid xs={12} md={10} item className={classes.sectionHeading}>
                                            <Typography variant="h4" color="textPrimary">
                                                More listings
                                            </Typography>
                                            <TitleDecoration />
                                        </Grid>
                                        <Grid
                                            className={classes.resultsList}
                                            container
                                            item
                                            xs={12}
                                            md={10}
                                            spacing={2}
                                            justifyContent="center"
                                        >
                                            <LinksList
                                                viewMode={viewMode}
                                                categoryDetails={{
                                                    title: currentCategory.title,
                                                    icon: currentCategory.icon
                                                }}
                                                links={restCategories}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                    <Loader style={{ minHeight: '40vh' }} loading={loadingCategories.currentMoreListings} />
                    {!loadingCategories?.currentListings && !loadingCategories?.currentMoreListings && hasMore && (
                        <div ref={lastListingRef}>
                            <Button onClick={handleLoadMore} type="button" className={classes.button}>
                                Load more
                            </Button>
                        </div>
                    )}
                </Container>
            )}
        </main>
    );
}

export default SingleCategory;
