import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    // listingResultsGridMode, listingResultsListMode,
    loadCategoriesFilter
} from 'store/ui';
import { setToolLinksSearchText, loadToolLinks, changePage } from 'store/links';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import Typography from '@material-ui/core/Typography';

import Breadcrumb from 'components/common/Breadcrumb';
import LinksList from 'components/toollinks/LinksList';
import ListingListSidebar from 'components/sidebars/ListingListSidebar';
import Loader from 'components/common/Loader';
import ResultsHeader from 'components/common/ResultsHeader';
import SearchInput from 'components/forms/SearchInput';
import breadcrumbimg from 'assets/images/header-bg.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '10vh'
    },
    cardArea: {
        padding: 0,
        '& .main-search-input': {
            margin: '0 auto 30px 0'
        }
    },
    linksList: {
        alignItems: 'flex-start',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            alignContent: 'flex-start'
        }
    },
    noResults: {
        textAlign: 'center',
        marginTop: '150px'
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchResults() {
    const dispatch = useDispatch();
    const query = useQuery();

    const classes = useStyles();

    const [showFiltersOnMobile, setShowFiltersOnMobile] = useState(false);

    const filters = useSelector(({ ui }) => ui.listingResults.filters);
    const linksLoading = useSelector(({ entities }) => entities.toolLinks.loading.all);
    const linksPagination = useSelector(({ entities }) => entities.toolLinks.pagination);
    const linksList = useSelector(({ entities }) => entities.toolLinks.list);
    const searchText = useSelector(({ entities }) => entities.toolLinks.searchParams.searchText);
    // const viewMode = useSelector(({ ui }) => ui.listingResults.viewMode);

    useEffect(() => {
        dispatch(loadCategoriesFilter());
    }, [dispatch]);

    useEffect(() => {
        const queryCategory = query.get('category');
        if (queryCategory) dispatch(loadToolLinks({ categories: [parseInt(queryCategory)] }));
        else dispatch(loadToolLinks());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const filteredCategories = filters.categories?.items?.filter((cat) => cat.active).map((cat) => cat.id);
        if (filteredCategories.length > 0) dispatch(loadToolLinks({ categories: filteredCategories }));
        else dispatch(loadToolLinks());
    }, [dispatch, filters]);

    // const handleViewGridMode = () => dispatch(listingResultsGridMode());
    // const handleViewListMode = () => dispatch(listingResultsListMode());

    const handleTextChange = (e) => {
        dispatch(setToolLinksSearchText(e.target.value));
    };

    const handleChangePage = (page) => {
        const filteredCategories = filters?.categories?.items?.filter((cat) => cat.active).map((cat) => cat.id);
        dispatch(changePage({ categories: filteredCategories, currentPage: page }));
    };

    const handleSubmit = (values) => {
        const filteredCategories = filters?.categories?.items?.filter((cat) => cat.active).map((cat) => cat.id);
        dispatch(loadToolLinks({ categories: filteredCategories }));
    };

    return (
        <main className={classes.root}>
            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="Search" img={breadcrumbimg} />

            {/* Place List */}
            <section style={{ padding: '0 5vw 5vh 5vw' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <SearchInput
                            style={{
                                marginTop: '20px'
                            }}
                            placeholder="What are you looking for?"
                            defaultValue={searchText}
                            onChange={handleTextChange}
                            onSubmit={handleSubmit}
                        />
                    </Grid>

                    <Grid container item xs={12} spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <ResultsHeader
                                changePage={handleChangePage}
                                pagination={linksPagination}
                                // viewMode={viewMode}
                                // onGridViewClick={handleViewGridMode}
                                // onListViewClick={handleViewListMode}
                            />
                        </Grid>

                        <Hidden smDown>
                            <Grid item xs={12} md={4}>
                                <ListingListSidebar />
                            </Grid>
                        </Hidden>

                        <Hidden mdUp>
                            <IconButton onClick={() => setShowFiltersOnMobile((bool) => !bool)}>
                                <SettingsInputCompositeOutlinedIcon />
                            </IconButton>
                        </Hidden>

                        {showFiltersOnMobile && (
                            <Grid item xs={12} md={4}>
                                <ListingListSidebar />
                            </Grid>
                        )}

                        <Grid container item xs={12} md={8} spacing={2} className={classes.linksList}>
                            <Loader loading={linksLoading} />

                            {!linksLoading && (
                                <>
                                    {linksList?.length > 0 ? (
                                        <>
                                            <Hidden smDown>
                                                <LinksList viewMode="list" links={linksList} />
                                            </Hidden>
                                            <Hidden mdUp>
                                                <LinksList viewMode="grid" links={linksList} />
                                            </Hidden>
                                            <Grid item xs={12}>
                                                <ResultsHeader
                                                    changePage={handleChangePage}
                                                    pagination={linksPagination}
                                                    hideResults
                                                    // viewMode={viewMode}
                                                    // onGridViewClick={handleViewGridMode}
                                                    // onListViewClick={handleViewListMode}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid
                                            container
                                            item
                                            className={classes.noResults}
                                            xs={11}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography variant="body1" color="textSecondary">
                                                No results
                                            </Typography>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </main>
    );
}

export default SearchResults;
