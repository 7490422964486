import { parsePath } from 'utils/helpers';

const navigationConfig = {
    headermenu: [
        {
            title: 'Home',
            icon: 'home_outlined',
            path: parsePath()
        },
        {
            title: 'Categories',
            icon: 'apps_outlined',
            path: parsePath('/categories/all')
        },
        {
            title: 'Search',
            icon: 'search_outlined',
            path: parsePath('/search')
        }
    ],
    footermenu: [
        {
            path: parsePath('/terms&conditions'),
            title: 'Terms & Conditions'
        },
        {
            path: parsePath('/privacy-policy'),
            title: 'Privacy Policy'
        }
    ]
};

export default navigationConfig;
