export function parsePath(uri = '') {
    return `${process.env.PUBLIC_URL}${uri}`;
}

export const mantainancePath = '/mantainance';

export const calculatePagination = (apiPagination, totals) => {
    if (!apiPagination || !totals) return;

    const { currentPage, pageSize } = apiPagination;

    let firstIndex = 0;
    let lastIndex = 0;

    if (totals !== 0) {
        if (currentPage === 1) firstIndex = 1;
        else firstIndex = (currentPage - 1) * pageSize + 1;

        if (currentPage === 1) lastIndex = pageSize;
        if (totals < pageSize * currentPage) lastIndex = totals;
        else lastIndex = pageSize * currentPage;
    }

    return {
        currentPage,
        firstIndex,
        lastIndex,
        ...apiPagination,
        totalResults: totals
    };
};
