import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Title from 'components/headings/Title';
import BannerOneSearchInput from './BannerOneSearchInput';

import bannerImage from 'assets/images/bannerImage.jpg';

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        maxHeight: '350px',
        objectFit: 'scale-down'
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        boxShadow: '1px 1px 3px #DCDCDC',
        position: 'relative',
        width: '100%',
        zIndex: 99,
        color: '#fff',
        textAlign: 'left',
        paddingTop: '120px',
        paddingBottom: '20px',
        marginBottom: '30px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px'
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        },
        '& .category-item': {
            width: '120px'
        }
    },
    title: {
        [theme.breakpoints.up('md')]: {
            marginTop: '35px'
        }
    }
}));

export default function BannerOne() {
    const classes = useStyles();

    const sectiondata = useSelector(({ ui }) => ui.textContent);
    return (
        <header className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Title
                            className={classes.title}
                            title={sectiondata.herobanners.banner1.title}
                            size="small"
                            subtitle={sectiondata.herobanners.banner1.content}
                            subTitleSize="large"
                        />

                        <BannerOneSearchInput />
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={6}>
                            <img className={classes.image} src={bannerImage} alt="Banner" />
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </header>
    );
}
