import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SmallCard from 'components/cards/SmallCard';

const useStyles = makeStyles((theme) => ({
    description: {
        maxHeight: '10rem',
        overflow: 'hidden'
    },
    root: {
        marginTop: '20px',
        textAlign: 'center'
    }
}));

export default function BannerOneCategories({ description, items }) {
    const classes = useStyles();

    if (!description && items?.length < 1) return null;

    return (
        <Grid container spacing={4} justifyContent="center" className={classes.root}>
            <Grid className={classes.description} item xs={8}>
                <Typography variant="body1">{description}</Typography>
            </Grid>
            {items && (
                <Grid container item xs={8} justifyContent="center">
                    {items?.map((item, index) => {
                        return (
                            <Grid container justifyContent="center" item xs={12} sm={6} lg={4} key={index}>
                                <SmallCard item={item} />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Grid>
    );
}
