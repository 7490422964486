import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinkItemHorizontal from './LinkItemHorizontal';
import LinkItemCard from './LinkItemCard';

const LinksList = ({ categoryDetails, links, viewMode = 'list' }) => {
    if (!links || !Array.isArray(links)) return null;

    if (viewMode === 'list')
        return links.map((link, index) => (
            <Grid key={link.id + index} item xs={11}>
                <LinkItemHorizontal categoryDetails={categoryDetails} link={link} />
            </Grid>
        ));
    if (viewMode === 'grid')
        return (
            <Grid item xs={11} container spacing={2}>
                {links.map((link, index) => (
                    <Grid item key={link.id + index} xs={12} sm={6} md={4}>
                        <LinkItemCard categoryDetails={categoryDetails} link={link} />
                    </Grid>
                ))}
            </Grid>
        );
};

export default LinksList;
