const englishContent = {
    general: {
        appTitle: 'Top3',
        appLogo: '/images/ss-web-36.svg',
        creator: 'Smart Studios',
        creatorWebsite: 'https://smartstudios.io/'
    },
    content: {
        herobanners: {
            banner1: {
                // TODO change text
                title: 'Top curated listings at your fingertips',
                // 'Digital Tools',

                content: 'Search through thousands of curated listings of tools, products and services.'
                // 'These are the top tools, product and services we suggest. Contact us should want to discuss selecting the best tool for your project and/or integrate them with your systems.'
            }
        },
        termsAndConditions: {
            title: 'Terms & Conditions',
            content: 'This is where the terms and conditions will go'
        },
        privacyPolicy: {
            title: 'Privacy Policy',
            content: 'This is where the privacy policy will go'
        }
    },
    footerdata: {
        sociallinks: [
            {
                icon: 'facebook',
                url: 'https://www.facebook.com/smartstudiosmalta/'
            },
            {
                icon: 'twitter',
                url: 'https://twitter.com/SmartStudiosLtd/'
            },
            {
                icon: 'linkedin',
                url: 'https://www.linkedin.com/company/smartstudios/'
            },
            {
                icon: 'instagram',
                url: 'https://www.instagram.com/smartstudiosltd/'
            }
        ]
    }
};
export default englishContent;
