import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadCategories, loadMoreCategories } from 'store/categories';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Loader from 'components/common/Loader';
import Breadcrumb from 'components/common/Breadcrumb';
import Button from 'components/common/Button';
import PopularCategories from 'components/other/categories/PopularCategories';

const useStyles = makeStyles((theme) => ({
    button: {
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: '10vh'
    },
    root: {},
    categoriesWrapper: {
        padding: '40px 0 80px 0'
    }
}));

function AllCategories() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const categoriesList = useSelector(({ entities }) => entities.categories.list);
    const hasMore = useSelector(({ entities }) => entities.categories.hasMore);
    const loadingCategories = useSelector(({ entities }) => entities.categories.loading.all);
    const loadingMoreCategories = useSelector(({ entities }) => entities.categories.loading.more);
    const pagination = useSelector(({ entities }) => entities.categories.pagination);

    const observer = useRef(null);
    const lastListingRef = useCallback(
        (node) => {
            if (loadingMoreCategories || loadingCategories) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) handleLoadMore();
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line
        [loadingMoreCategories, loadingCategories, hasMore, pagination]
    );

    useEffect(() => {
        dispatch(loadCategories());
    }, [dispatch]);

    const handleLoadMore = () => {
        dispatch(loadMoreCategories({ currentPage: pagination.nextPage }));
    };

    return (
        <main className={classes.root}>
            <Breadcrumb CurrentPgTitle="All categories" />

            <section className={classes.categoriesWrapper}>
                <Container maxWidth="lg">
                    <Loader style={{ minHeight: '40vh' }} loading={loadingCategories} />
                    {!loadingCategories && (
                        <Grid container>
                            <PopularCategories catitems={categoriesList} />
                        </Grid>
                    )}
                    <Loader style={{ minHeight: '40vh' }} loading={loadingMoreCategories} />
                    {!loadingCategories && !loadingMoreCategories && hasMore && (
                        <div ref={lastListingRef}>
                            <Button onClick={handleLoadMore} type="button" className={classes.button}>
                                Load more
                            </Button>
                        </div>
                    )}
                </Container>
            </section>
        </main>
    );
}

export default AllCategories;
