import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createCategoriesFilter } from 'utils/widgetFunctions';
import { formatCategoriesList } from 'utils/dataParsers';
import navigationConfig from 'config/navigationConfig';
import englishContent from 'store/englishContent';

const slice = createSlice({
    name: 'ui',
    initialState: {
        appInformation: englishContent.general,
        footerContent: {
            general: englishContent.footerdata,
            menuItems: navigationConfig.footermenu
        },
        listingResults: {
            viewMode: 'grid',
            filters: {
                categories: createCategoriesFilter(formatCategoriesList([])),
                categoriesRaw: [],
                loading: false
            }
        },
        mantainanceMode: false,
        sidebar: {
            menuItems: navigationConfig.headermenu
        },
        textContent: englishContent.content,
        theme: 'light'
    },
    reducers: {
        themeDark: (state, action) => {
            state.theme = 'dark';
        },

        themeLight: (state, action) => {
            state.theme = 'light';
        },

        mantainanceModeEnabled: (state, action) => {
            state.mantainanceMode = true;
        },

        mantainanceModeDisabled: (state, action) => {
            state.mantainanceMode = false;
        },

        listingResultsGridMode: (state, action) => {
            state.listingResults.viewMode = 'grid';
        },

        listingResultsListMode: (state, action) => {
            state.listingResults.viewMode = 'list';
        },

        categoriesFilterRequestFailed: (state, action) => {
            state.listingResults.filters.loading = false;
        },

        categoriesFilterRequested: (state, action) => {
            state.listingResults.filters.loading = true;
        },

        categoriesFilterPopulated: (state, action) => {
            state.listingResults.filters.categoriesRaw = action.payload.documents;
            state.listingResults.filters.loading = false;
        },

        filterCategoriesByListings: (state, action) => {
            const categoriesToFilter = state.listingResults.filters.categoriesRaw;
            state.listingResults.filters.categories = createCategoriesFilter(formatCategoriesList(categoriesToFilter));
        },

        categoriesFilterUpdated: (state, action) => {
            let { items } = state.listingResults.filters.categories;
            const { id, active } = action.payload;
            state.listingResults.filters.categories.items = items.map((cat) => {
                if (parseInt(cat.id) === parseInt(id)) {
                    return { ...cat, active };
                }
                return cat;
            });
        }
    }
});

export const {
    themeDark,
    themeLight,
    mantainanceModeEnabled,
    mantainanceModeDisabled,
    filterCategoriesByListings,
    listingResultsGridMode,
    listingResultsListMode,
    categoriesFilterRequestFailed,
    categoriesFilterPopulated,
    categoriesFilterRequested,
    categoriesFilterUpdated
} = slice.actions;

export default slice.reducer;

export const loadCategoriesFilter = () => async (dispatch, getState) => {
    const listings = getState().entities?.toolLinks?.list;

    await dispatch(
        apiCallBegan({
            apiId: 'main',
            url: '/Category/All?featured=-1&pageSize=9999',
            onStart: categoriesFilterRequested.type,
            onSuccess: categoriesFilterPopulated.type,
            onError: categoriesFilterRequestFailed.type
        })
    );

    return dispatch(filterCategoriesByListings(listings));
};
